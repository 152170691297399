import React, { useState, useEffect } from "react";
import { AzureOpenAI } from "openai";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Clock } from 'lucide-react';
import logo from './assets/logo.png';

// Login Form Component
const LoginFormComponent = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const validCredentials = [
    { username: "leventilhan", password: "Levo.3545" },
    { username: "yasemin", password: "Ys.2024!" },
    { username: "tanzer", password: "Tk.2024!" },
    { username: "efeturkel", password: "Et.2025!" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = validCredentials.find(
      cred => cred.username === username && cred.password === password
    );
    
    if (user) {
      onLoginSuccess();
      setLoginError("");
    } else {
      setLoginError("Geçersiz kullanıcı adı veya şifre!");
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="card p-4 shadow" style={{ width: "400px" }}>
        <div className="text-center mb-4">
          <img src={logo} alt="Logo" className="mb-3" style={{ width: "250px"}} />
          <h2>Giriş Yap</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Kullanıcı Adı</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Şifre</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              required
            />
          </div>
          {loginError && <div className="alert alert-danger">{loginError}</div>}
          <button type="submit" className="btn btn-primary w-100">Giriş Yap</button>
        </form>
      </div>
    </div>
  );
};

// Image Generator Component
const ImageGeneratorComponent = ({ onLogout, remainingImages, sessionTimeout, handleActivity }) => {
  const [prompt, setPrompt] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSize, setSelectedSize] = useState("1024x1024");

  const sizes = {
    "Kare (1024x1024)": "1024x1024",
    "Dikey (1024x1792)": "1024x1792",
    "Yatay (1792x1024)": "1792x1024"
  };

  const client = new AzureOpenAI({
    apiKey: process.env.REACT_APP_AZURE_OPENAI_API_KEY,
    endpoint: process.env.REACT_APP_AZURE_OPENAI_ENDPOINT,
    apiVersion: "2023-12-01-preview",
    deploymentId: "dall-e-3",
    dangerouslyAllowBrowser: true
  });

  const generateImage = async () => {
    if (!prompt.trim()) {
      setError("Lütfen bir prompt girin");
      return;
    }

    if (remainingImages <= 0) {
      setError("Resim oluşturma hakkınız kalmadı!");
      return;
    }

    setLoading(true);
    setError(null);
    handleActivity();

    try {
      const result = await client.images.generate({
        prompt,
        n: 1,
        size: selectedSize,
        model: "dall-e-3"
      });

      setImage(result.data[0].url);
    } catch (err) {
      console.error("Error details:", err.response?.data || err);
      setError(`Hata: ${err.message || "Görsel oluşturulamadı"}`);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex align-items-center">
                  <img src={logo} alt="Logo" style={{ width: "150px"}} className="me-2" />
                  <h2 className="mb-0">CSC Bilişim - AI Görsel Oluşturma</h2>
                </div>
                <div className="text-end">
                  <div className="text-muted mb-2">
                    <Clock className="me-1" />
                    Oturum: {formatTime(sessionTimeout)}
                  </div>
                  <div className="badge bg-primary">
                    Kalan Resim: {remainingImages}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={prompt}
                  onChange={(e) => {
                    setPrompt(e.target.value);
                    handleActivity();
                  }}
                  placeholder="Prompt girin"
                />
              </div>
              <div className="mb-3">
                <select
                  className="form-select"
                  value={selectedSize}
                  onChange={(e) => {
                    setSelectedSize(e.target.value);
                    handleActivity();
                  }}
                >
                  {Object.entries(sizes).map(([key, value]) => (
                    <option key={key} value={value}>{key}</option>
                  ))}
                </select>
              </div>
              <button
                onClick={generateImage}
                disabled={loading || remainingImages <= 0}
                className="btn btn-primary w-100"
              >
                {loading ? "Yükleniyor..." : "Görsel Oluştur"}
              </button>
              {error && <div className="alert alert-danger mt-3">{error}</div>}
              {image && (
                <div className="mt-3">
                  <img src={image} alt="Generated" className="img-fluid" />
                </div>
              )}
            </div>
          </div>
          <div className="text-center mt-3">
            <button onClick={onLogout} className="btn btn-danger">
              Çıkış Yap
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main App Component
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [remainingImages, setRemainingImages] = useState(10);
  const [sessionTimeout, setSessionTimeout] = useState(30 * 60);
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    let timer;
    if (isLoggedIn) {
      timer = setInterval(() => {
        const now = Date.now();
        const timeSinceLastActivity = Math.floor((now - lastActivity) / 1000);
        
        if (timeSinceLastActivity >= 30 * 60) {
          handleLogout();
        } else {
          setSessionTimeout(30 * 60 - timeSinceLastActivity);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isLoggedIn, lastActivity]);

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    setRemainingImages(10);
    handleActivity();
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setRemainingImages(10);
  };

  return isLoggedIn ? (
    <ImageGeneratorComponent
      onLogout={handleLogout}
      remainingImages={remainingImages}
      sessionTimeout={sessionTimeout}
      handleActivity={handleActivity}
    />
  ) : (
    <LoginFormComponent onLoginSuccess={handleLogin} />
  );
};

export default App;